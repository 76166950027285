import { storeToRefs } from 'pinia';
import { useSingleBattleStore } from './singleBattle.store';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useUserStore } from '~/store/user/user.store';
import { EmodjiDelays } from '~/features/battles/constants/rules';
import { useBattlesEmojiesStore } from '~/features/battles/store/battlesEmojies.store';
import type { EmojiesListsKeys } from '~/features/battles/constants/emojies';
import { EBattlesStoreNames } from '~/features/battles/constants/general';
import { useAlertStore } from '~/store/alert/alert.store';

import type { IBattlesBattleFullInfoEntity, IBattlesEmodjiEntity, IMemberInBattle } from '~/repository/modules/battles';
import type { IPlayerDrop, IPlayerInfo, IPlayerRound } from '~/features/battles/types/battlePlayers.types';
import type { TPossibleNull } from '~/types/Shared.types';
import type { TPersonWindowButtonsState } from '~/features/battles/components/PlayerWindow/PlayerWindow.types';
import type { IBattlesErrorCode16 } from '~/repository/modules/battles/BattlesErrors.types';

/* Хранилище состояний пользователей */
export const usePlayersStore = defineStore(EBattlesStoreNames.BATTLE_PLAYERS, () => {
  /* -- Imports -- */
  /* Импорт апи модуля */
  const {
    $api: { battles: BattlesApiService },
    $i18n: { t },
  } = useNuxtApp();

  /* Импорт сторов */
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const alertStore = useAlertStore();
  const singleBattleStore = useSingleBattleStore();
  const battlesEmojiesStore = useBattlesEmojiesStore();

  /* -- Initialisation -- */
  const { battle, battleAnimator, battleState, battleAllItems, battleActiveRound, battleRounds } =
    storeToRefs(singleBattleStore);
  const { isAuth } = storeToRefs(authStore);

  /* Массив состояний пользователей относительно раунда */
  const roundStatuses = ref<number[]>([]);
  /* Объект состояний загрузок кнопок */
  const personButtonsState = reactive<TPersonWindowButtonsState>(createButtonsState());

  /* -- Getters -- */
  const players = computed<IPlayerInfo[]>(() => {
    const result: IPlayerInfo[] = [];

    if (!battle.value) return result;
    const offers = [null, 'start', 'start-bots'];

    const slots = battle.value.slots;

    const isInBattle = battle.value.members.find((member) => member.userId === userStore.userId);

    for (let slot = 1; slot <= slots; slot++) {
      const member = battle.value.members.find((member) => member.slot === slot);
      if (!member) {
        if (battleState.value.created) {
          result.push({
            drops: [],
            host: false,
            info: null,
            offer: null,
            round: getRound(-1),
            slot,
            state: isAuth.value && !isInBattle ? 'join' : 'waiting',
            total: 0,
            won: false,
          });
        }
        continue;
      }

      const won = member.id === battle.value.winnerId;

      result.push({
        drops: getDrops(member.id, won),
        host: member.id === battle.value.hostId,
        info: {
          ...member,
          me: member.userId === userStore.userId,
        },
        offer: offers[member.ready] as TPossibleNull<'start' | 'start-bots'>,
        round: getRound(member.id),
        slot,
        state: member.bot ? 'bot' : 'player',
        total: +calculatePlayerTotal(member.id),
        won,
      });
    }
    return result;
  });

  /* Выиграл ли "я" */
  const isMeWon = computed(() => {
    return !!players.value.find((player) => player.info?.userId === userStore.userId && player.won);
  });

  /* Являюсь ли "Я" хостом */
  const isMeHost = computed(() => {
    return !!players.value.find((player) => player.info?.userId === userStore.userId && player.host);
  });

  /* Игрок ли Я */
  const isMePlayer = computed(() => {
    return !!players.value.find((player) => player.info?.userId && player.info?.userId === userStore.userId);
  });

  /* Зритель ли "я" */
  const isMeSpectator = computed(() => {
    if (!userStore.userId) return true;

    const userIds = players.value.map((player) => player.info?.userId);
    return !userIds.includes(userStore.userId);
  });

  /* Моя информация, если я игрок */
  const me = computed(() => {
    return players.value.find((player) => player.info?.userId === userStore.userId) || null;
  });

  const highestTempWin = computed(() => {
    return Math.max(...players.value.map((player) => player.total));
  });

  /* -- Actions -- */
  /* Подсчет суммы дропа игрока */
  const calculatePlayerTotal = (memberId: number) => {
    const drops = getDrops(memberId, false);
    return drops.reduce((prev, cur) => prev + cur.price, 0);
  };

  /* Функция для получения данных о раунде у игрока */
  const getRound = (memberId: number) => {
    const roundData: IPlayerRound = {
      drops: [],
      won: false,
    };
    const activeRound = battleActiveRound.value;
    if (!activeRound || !activeRound.results || !activeRound.prizes) return roundData;

    const result = activeRound.results.find((result) => result.memberId === memberId);
    roundData.won = (result?.total ?? -1) === Math.max(...activeRound.results.map((result) => result.total));

    for (const prize of activeRound.prizes) {
      if (prize.memberId !== memberId) continue;
      if (!prize.item || !prize.item.itemId) continue;

      roundData.drops.push(prize.item.itemId);
    }

    return roundData;
  };

  /* Подсчёт суммы всех коинов за все раунды и за все призы */
  const battleCoinsCalculate = () => {
    let coinsValue = 0;

    if (!battle.value) return 0;

    const member = battle.value.members.find((member) => member.userId === userStore.userId);

    if (!member) return 0;

    (battleRounds.value || []).forEach((round) =>
      (round.results || []).forEach((res) => {
        if (res.memberId === member.id) {
          coinsValue += res.coins ? res.coins : 0;
        }
      }),
    );
    return coinsValue;
  };

  /* Функция для получения данных о дропе у игрока */
  const getDrops = (memberId: number, won: boolean) => {
    const result: IPlayerDrop[] = [];

    if (!battle.value) return result;

    if (won && battleAnimator.value.last && battleAnimator.value.played) {
      for (const round of battle.value.rounds) {
        if (!round.prizes) continue;

        const items: IPlayerDrop[] = [];

        round.prizes.forEach((prize) => {
          const item = battleAllItems.value.get(prize.item!.itemId);
          items.push({
            ...item!,
            price: prize.item?.price || 0,
          } as IPlayerDrop);
        });

        round.results.forEach((res, index) => {
          if (items[index]) items[index].coins = res.coins;
        });

        if (!items.length) continue;

        result.push(...items);
      }

      return result;
    }
    for (const round of battle.value.rounds) {
      if (!round.prizes) continue;
      if (round.number > battleAnimator.value.round) continue;
      // коммент оставляю умышленно, чтобы вернуться. Ниже строчка закомменчена, чтобы доступ к дропу был в начале раунда
      // if (round.number === battleAnimator.value.round) continue;

      const res = round.results.find((miniResult) => miniResult.memberId === memberId);
      if (!res) continue;

      const prizes = round.prizes.filter((prize) => prize.memberId === memberId);
      if (!prizes.length) continue;

      for (const prize of prizes) {
        if (!prize.item) continue;

        const item = battleAllItems.value.get(prize.item.itemId || 0);
        if (!item) continue;

        result.push({
          ...item,
          coins: res.coins,
          price: prize.item.price,
        });
      }
    }

    return result;
  };

  /* Функция выфзывающаяся для окончания раунда */
  const endRound = (slot: number) => {
    if (roundStatuses.value.includes(slot)) return;
    roundStatuses.value.push(slot);

    if (roundStatuses.value.length === players.value.length) {
      roundStatuses.value = [];
      singleBattleStore.loadNext();
    }
  };

  /* Функция для входа игрока */
  const join = async (slot: number) => {
    if (!battle.value) return;
    const wasMeSpectator = isMeSpectator.value;

    try {
      personButtonsState.joining = slot;

      const result = await BattlesApiService.joinBattle({
        battleUlid: battle.value.ulid,
        slot,
      });

      if (wasMeSpectator) await singleBattleStore.closePage();
      if (result.balance) userStore.setNewBalance(result.balance);

      return result;
    } catch (e) {
      const error = e as IBattlesErrorCode16;
      personButtonsState.joining = null;

      if (error && error.message && error.code && error.battleUlid) {
        alertStore.showError({
          message: t('battles.error.alreadyInBattle'),
          title: t('alerts.joiningBattle'),
        });
      }

      return e;
    }
  };

  /* Функция для предложения начала игрока */
  const start = async (withBots: boolean) => {
    if (!battle.value) return;

    if (withBots) personButtonsState.startingWithBots = true;
    else personButtonsState.startingWithPlayers = true;

    try {
      await BattlesApiService.startBattle({
        battleUlid: battle.value!.ulid,
        bots: withBots,
      });
      personButtonsState.cancelling = false;
    } catch {
      if (withBots) personButtonsState.startingWithBots = false;
      else personButtonsState.startingWithPlayers = false;
    }
  };

  /* Функция для отмены предложения игрока */
  const cancel = async () => {
    if (!battle.value) return;

    try {
      personButtonsState.cancelling = true;

      await BattlesApiService.cancelBattleStart({
        battleUlid: battle.value!.ulid,
      });
    } catch {
      personButtonsState.cancelling = false;
    }
  };

  /* Отправить эмодзи в батле */
  const sendEmodji = () => {
    let emojiesToSend: { dir: EmojiesListsKeys; key: string }[] = [];

    /* Отправляем для других игроков */
    const sendDebounce = GlobalUtils.Functions.debounceFn(() => {
      const strEmojies = emojiesToSend.map(({ key, dir }) => battlesEmojiesStore.wrapEmojiKey(dir, key)).join(',');
      BattlesApiService.sendEmodji({
        battleUlid: battle.value!.ulid,
        content: strEmojies,
      });
      emojiesToSend = [];
    }, EmodjiDelays.asyncSend);

    /* Рендерим у себя без ожидания сокетов */
    const renderDebounce = GlobalUtils.Functions.throttleFn((emodjiKey: string, emodjiDirection: EmojiesListsKeys) => {
      battlesEmojiesStore.setEmoji({
        battleUlid: battle.value!.ulid,
        content: battlesEmojiesStore.wrapEmojiKey(emodjiDirection, emodjiKey),
        memberId: me.value?.info?.id ?? 0,
      });
    }, EmodjiDelays.syncClient);

    const addBeforeSend = GlobalUtils.Functions.throttleFn((emodjiKey: string, emodjiDirection: EmojiesListsKeys) => {
      emojiesToSend.push({ dir: emodjiDirection, key: emodjiKey });
    }, EmodjiDelays.syncClient);

    return function (emodjiKey: string, direction: EmojiesListsKeys) {
      if (!battle.value || isMeSpectator.value) return;
      renderDebounce(emodjiKey, direction);
      addBeforeSend(emodjiKey, direction);
      sendDebounce();
    };
  };

  /* Обработчик сокетного события для отрисовки эмоджи */
  function handleEmojiListener(data: { emoji: IBattlesEmodjiEntity }) {
    if (!data || !data.emoji) return;
    if (battle.value && battle.value.ulid !== data.emoji.battleUlid) return;

    /* Значит отправлял "я". Показывать второй раз эмоджи не нужно. Оно уже было отрисовано синхронно */
    if (me.value?.info?.id === data.emoji.memberId) return;

    const emojiesReceived = data.emoji.content.split(',');

    for (let i = 0; i < emojiesReceived.length; i++) {
      setTimeout(
        () => {
          battlesEmojiesStore.setEmoji({
            battleUlid: data.emoji.battleUlid,
            content: emojiesReceived[i],
            memberId: data.emoji.memberId,
          });
        },
        EmodjiDelays.syncClient * 2 * i,
      );
    }
  }

  /* Обработчик сокетного события для изменения состояний кнопок */
  function handleBattleChanging(data: { battle: IBattlesBattleFullInfoEntity }) {
    if (!data || !data.battle) return;
    if (!battle.value) return;
    if (battle.value.ulid !== data.battle.ulid || battleState.value.finished) return;

    const myPrevSlot = battle.value!.members.find((member) => member.userId === userStore.userId)?.slot;
    const myNewSlot = data.battle.members.find((member) => member.userId === userStore.userId)?.slot;

    if (myNewSlot === undefined && myPrevSlot !== undefined && personButtonsState.leaving) {
      personButtonsState.leaving = false;
    }

    if (myNewSlot === undefined) return;

    if (personButtonsState.joining && !myPrevSlot) {
      personButtonsState.joining = null;
      return;
    }

    const prevMe = battle.value!.members.find((member) => member.slot === myPrevSlot) || ({} as IMemberInBattle);
    const newMe = data.battle.members.find((member) => member.slot === myNewSlot) || ({} as IMemberInBattle);

    if (personButtonsState.startingWithPlayers && newMe.ready === 1 && prevMe.ready !== 1) {
      personButtonsState.startingWithPlayers = false;

      return;
    }

    if (personButtonsState.startingWithBots && newMe.ready === 2 && prevMe.ready !== 2) {
      personButtonsState.startingWithBots = false;

      return;
    }

    if (personButtonsState.cancelling && newMe.ready === 0 && prevMe.ready !== 0) {
      personButtonsState.cancelling = false;
    }
  }

  /* Функция обнуления состояния стора */
  const $reset = () => {
    Object.assign(personButtonsState, createButtonsState());
    roundStatuses.value = [];
  };

  /* -- Returns -- */
  return {
    $reset,
    battleCoinsCalculate,
    cancel,
    endRound,
    handleBattleChanging,
    handleEmojiListener,
    highestTempWin,
    isMeHost,
    isMePlayer,
    isMeSpectator,
    isMeWon,
    join,
    me,
    personButtonsState,
    players,
    sendDebouncedEmoji: sendEmodji(),
    start,
  };
});

/* Функция создания дефолтного состояния кнопок */
function createButtonsState(): TPersonWindowButtonsState {
  return { cancelling: false, joining: null, leaving: false, startingWithBots: false, startingWithPlayers: false };
}
