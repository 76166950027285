import type { IBattlesEmodjiEntity } from '~/repository/modules/battles';
import type { EmojiesListsKeys } from '~/features/battles/constants/emojies';
import type { IBattlesEmodjiInStore } from '~/features/battles/types/battlesStore.types';
import { EBattlesStoreNames } from '~/features/battles/constants/general';

const SEPARATOR = '-';

/* Стор отвечающий за хранение и оторажение эмоджи  */
export const useBattlesEmojiesStore = defineStore(EBattlesStoreNames.EMOJIES, () => {
  /* -- Initialisation -- */
  /* Коллекция эмоджи взависимости от их места применения */
  const emojies = ref<Map<EmojiesListsKeys, IBattlesEmodjiInStore[]>>(new Map());

  /* -- Actions -- */
  /* Добавляем эмоджи в коллекцию */
  const setEmoji = (emoji: IBattlesEmodjiEntity) => {
    const { content } = emoji;

    const [key, emodjiKey] = content.split(SEPARATOR) as [EmojiesListsKeys, string];

    if (!emojies.value.has(key)) {
      emojies.value.set(key, []);
    }
    const list = emojies.value.get(key)!;
    list.push({
      ...emoji,
      content: emodjiKey,
      id: GlobalUtils.Maths.createUniqueId(12),
    });
  };

  /* Получаем весь список эмоджи для определенного места применения */
  const getEmojiesList = (direction: EmojiesListsKeys, memberId?: number) => {
    if (!memberId || !emojies.value.has(direction)) return [];
    return emojies.value.get(direction)!.filter((emodjiItem) => {
      return emodjiItem.memberId === memberId;
    });
  };

  /* Функция удаления эмоджи из списка */
  const deleteEmoji = (emodjiId: string, direction: EmojiesListsKeys) => {
    if (!emojies.value.has(direction)) return;
    const list = emojies.value.get(direction)!;
    const index = list.findIndex((item) => item.id === emodjiId);
    if (index > -1) list.splice(index, 1);
  };

  /* -- Helpers -- */

  /* Оборачиваем эмоджи перед отправкой */
  const wrapEmojiKey = (key: EmojiesListsKeys, emojiKey: string) => {
    return key + SEPARATOR + emojiKey;
  };

  /* -- Returns -- */
  return {
    deleteEmoji,
    emojies,
    getEmojiesList,
    setEmoji,
    wrapEmojiKey,
  };
});
